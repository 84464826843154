import { Box, Button, HStack, Image, Spacer, Text, useMediaQuery, VStack } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AnimatedHeading } from "./Animated.js";
import  MenuButtonX  from "./MenuButton.jsx";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
  } from '@chakra-ui/react'

const Header = ({location, setLocation}) => {
    const [isLargerThan800] = useMediaQuery("(min-width: 800px)")
    const navigate = useNavigate();
    const [isOpen, setOpen] = React.useState(false);

    return (
      <Box
        pos={"fixed"}
        top={0}
        left={
          !isLargerThan800
            ? "0vw"
            : location == "home"
            ? "20vw"
            : location == "about"
            ? "0vw"
            : location == "services"
            ? "10vw"
            : "20vw"
        }
        w={isLargerThan800 ? "80vw" : "100vw"}
        p={[4, 8, 12]}
        pt={[8, 8, 12]}
        //zIndex={1}
        h={isLargerThan800 ? "auto" : "100vh"}
        //blur filter
        backdropFilter={isLargerThan800 ? "blur(20px)" : "blur(0px)"}
        
        //let touch events pass through
        pointerEvents="none"
      >
        <HStack w={"100%"}
            pointerEvents="auto"
            h="0px"
        >
          {/* Logo */}
          <HStack spacing={10}>
            <Image
              src={"./assets/images/logo-img.png"}
              alt={"Distroplay Logo"}
              w={200}
            />
            {isLargerThan800 && (
              <Text
                color="textPrimary.body"
                fontFamily="Gilroy"
                fontSize={[12]}
                textAlign="left"
              >
                A Global Music Distribution & Publishing Administration Services
              </Text>
            )}
          </HStack>
          <Spacer />
          <Menu>
            <MenuButton
              as={Button}
              fontFamily={"Gilroy"}
              variant="outline"
              borderRadius={"50px"}
              mr={4}
              fontSize={[12]}
            >
              Login
            </MenuButton>
            <MenuList fontFamily={"Gilroy"}>
              <MenuItem>
              <a href="https://distroplay.mcms.software/app/index.phtml">Distributors</a>
              </MenuItem>
              <MenuItem>
              <a href="https://dash.distroplay.co/panel">Labels</a>
              </MenuItem>
              <MenuItem>
              <a href="https://central.distroplay.in/login">Artists</a>
              </MenuItem>
            </MenuList>
          </Menu>
          {isLargerThan800 ? (
            <Button
              fontFamily={"Gilroy"}
              fontSize={[12]}
              bg={"block2.base"}
              px={[8]}
              color={"textSecondary.base"}
              borderRadius="50px"
              _hover={{
                color: "block2.base",
                borderColor: "block2.base",
                borderWidth: "1px",
                bg: "transparent",
              }}
            >
              Services
              {/* right arrow icon */}
            </Button>
          ) : (
            <MenuButtonX
              isOpen={isOpen}
              onClick={() => setOpen(!isOpen)}
              strokeWidth="4"
              color="#00000070"
              //transition={{ ease: "easeOut", duration: 0.2 }}
              width="36"
              height="13"
              style={{ marginLeft: "2rem" }}
            />
          )}
        </HStack>
        <AnimatePresence>
          {!isLargerThan800 && isOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeOut", duration: 0.6 }}
              style={{
                width: "100%",
                height: "100%",
                
              }}
            >
              <VStack
                w={"100%"}
                spacing={"-50%"}
                h="100vh"
                bg="bgPrimary.base"
                alignItems={"flex-end"}
                justifyContent="flex-end"
                pointerEvents="auto"
              >

                {/* Stacked Cards rotated slightly */}
                <motion.div
                    initial={{
                        rotate: 10,
                        x: "30%",

                    }}
                    animate={{
                        rotate: -2,
                        x: "10%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },

                        
                    }}
                    exit={{
                        rotate: 10,
                        x: "30%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },
                        

                    }}
                    style={{
                        width: "80%",
                        height: "40%",
                    }}
                >
                    <Box
                        w="100%"
                        h="100%"
                        bg="green.400"
                        borderRadius="0px"
                        justifyContent={"flex-start"}
                        p={10}
                    >
                        <AnimatedHeading
                            color="textSecondary.base"
                            fontFamily="Giselle"
                            fontSize={35}
                            textAlign="left"
                        >
                            Contact
                        </AnimatedHeading>
                    </Box>
                </motion.div>
                <motion.div
                    initial={{
                        rotate: 10,
                        x: "30%",

                    }}
                    animate={{
                        rotate: -15,
                        x: "10%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },

                        
                    }}
                    exit={{
                        rotate: 10,
                        x: "30%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },
                        

                    }}
                    style={{
                        width: "80%",
                        height: "40%",
                    }}
                >
                    <Box
                        w="100%"
                        h="100%"
                        bg="block1.base"
                        borderRadius="0px"
                        justifyContent={"flex-start"}
                        p={10}
                        onClick={() => {
                            setOpen(false);
                            setTimeout(() => {
                                navigate("/home")
                                setLocation("home")
                            }, 400)
                        }}
                    >
                        <AnimatedHeading
                            color="textSecondary.base"
                            fontFamily="Giselle"
                            fontSize={35}
                            textAlign="left"
                        >
                            Home
                        </AnimatedHeading>
                    </Box>
                </motion.div>
                <motion.div
                    initial={{
                        rotate: 10,
                        x: "30%",

                    }}
                    animate={{
                        rotate: -20,
                        x: "10%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },

                        
                    }}
                    exit={{
                        rotate: 10,
                        x: "30%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },
                        

                    }}
                    style={{
                        width: "80%",
                        height: "40%",
                    }}
                >
                    <Box
                        w="100%"
                        h="100%"
                        bg="block2.base"
                        borderRadius="0px"
                        justifyContent={"flex-start"}
                        p={10}
                        onClick={() => {
                            setOpen(false);
                            setTimeout(() => {
                                navigate("/services")
                                setLocation("services")
                            }, 400)
                        }}
                    >
                        <AnimatedHeading
                            color="textSecondary.base"
                            fontFamily="Giselle"
                            fontSize={35}
                            textAlign="left"
                            
                        >
                            Services
                        </AnimatedHeading>
                    </Box>
                </motion.div>
                <motion.div
                    initial={{
                        rotate: 10,
                        x: "30%",

                    }}
                    animate={{
                        rotate: -30,
                        x: "10%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },

                        
                    }}
                    exit={{
                        rotate: 10,
                        x: "30%",
                        transition: { 
                            duration: 0.5,
                            ease: "easeInOut"
                        },
                        

                    }}
                    style={{
                        width: "80%",
                        height: "40%",
                    }}
                >
                    <Box
                        w="100%"
                        h="100%"
                        bg="block3.base"
                        borderRadius="0px"
                        justifyContent={"flex-start"}
                        p={10}
                        onClick={() => {
                            setOpen(false);
                            setTimeout(() => {
                                navigate("/about")
                                setLocation("about")
                            }, 400)
                        }}
                    >
                        <AnimatedHeading
                            color="textSecondary.base"
                            fontFamily="Giselle"
                            fontSize={35}
                            textAlign="left"
                            
                        >
                            About
                        </AnimatedHeading>
                    </Box>
                </motion.div>
                
              </VStack>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    );
}
export default Header