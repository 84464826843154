import { AnimatePresence } from "framer-motion";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./navigation/Home";
import About from "./navigation/About";
import Services from "./navigation/Services";

function AnimatedRoutes(props) {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Home 
            LocationContext={props.LocationContext}
          />} />
        <Route path="/home" element={<Home LocationContext={props.LocationContext} />} />
        <Route path="/about" element={<About LocationContext={props.LocationContext} />} />
        <Route path="/services" element={<Services LocationContext={props.LocationContext} />} />
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;