import {
  Avatar,
  Box,
  Circle,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Square,
  Text,
  theme,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import animDistro1 from "../anim/anim_distro1.json";
import animDistro2 from "../anim/anim_distro2.json";
import { AnimatedHeading } from "../components/Animated";
import { TypingAnimation } from "../components/Animated";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import Footer from "../components/Footer";

const TeamMember = (props) => {
  return (
    <VStack>
      <Image
        borderRadius="full"
        boxSize="150px"
        name="Segun Adebayo"
        src={props.image}
        objectFit="contain"
        style={{
          objectFit: "contain",
        }}
      />
      <Text
        textAlign={"center"}
        fontSize={20}
        color="textPrimary.heading"
        fontFamily="Gilroy"
        fontWeight={600}
        pt={5}
      >
        {props.name}
      </Text>
      <Text
        textAlign={"center"}
        fontSize={15}
        color="textPrimary.body"
        fontFamily="Gilroy"
        fontWeight={400}
      >
        {props.role}
      </Text>
      <Text
        textAlign={"center"}
        fontSize={14}
        color="textPrimary.body"
        fontFamily="Gilroy"
        fontWeight={400}
      >
        {props.email}
      </Text>
    </VStack>
  );
};

const ServiceLeft = (props) => {
  return (
    <Box w="100%" px={[4, 8, 12, 16]}>
      <SimpleGrid
        w="100%"
        minChildWidth={["100%", "100%", "50%", "40%"]}
        spacing={10}
      >
        <Image src={props.image} alt={""} width={"100%"} height={"100%"} />
        <VStack
          w="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <AnimatedHeading
            textAlign={"justify"}
            fontSize={[32, 32, 36, 36]}
            color="textPrimary.heading"
            fontFamily="Giselle"
          >
            {props.title}
          </AnimatedHeading>
          <Text
            textAlign={"justify"}
            fontSize={16}
            color="textPrimary.body"
            fontFamily="Gilroy"
          >
            {props.text}
          </Text>
        </VStack>
      </SimpleGrid>
      <Divider orientation="horizontal" mt={8} borderColor="#00000040" />
    </Box>
  );
};
const ServiceRight = (props) => {
  return (
    <Box w="100%" px={[4, 8, 12, 16]}>
      <SimpleGrid
        w="100%"
        minChildWidth={["100%", "100%", "50%", "40%"]}
        spacing={20}
      >
        <VStack
          w="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <AnimatedHeading
            textAlign={"justify"}
            fontSize={[32, 32, 36, 36]}
            color="textPrimary.heading"
            fontFamily="Giselle"
          >
            {props.title}
          </AnimatedHeading>
          <Text
            textAlign={"justify"}
            fontSize={16}
            color="textPrimary.body"
            fontFamily="Gilroy"
          >
            {props.text}
          </Text>
        </VStack>
        <Image src={props.image} alt={""} width={"100%"} height={"100%"} />
      </SimpleGrid>
      <Divider orientation="horizontal" mt={8} borderColor="#00000040" />
    </Box>
  );
};

const Hero = () => {
  /**
    (She) Dariya Zagar: Chief Executive Officer

(He) Robby Busick: Chief Financial Officer

(He) Boris Smith: Chief Technical Officer

(She) Mandy Agor: Director of Business Development & Global Operations, SAARC Business Development Manager


(He) Abbey Nacar: Director of Sales & Distribution Operations

(He) Morris Walker: Client Relationship & Onboarding Manager

(She) Anna Taylor: Artist & Label Account Manager, Regional Manager & Country Head - India


(He) Miguel Carter: Support Specialist & Label Manager

(She) Abbie Nixon: Director of Right Management & YouTube Administrator

(He) Guilherme Marques: Head of Trust & Safety Operations

(She) Kylie Parker: Royalty & Reporting Administrator

(She) Stella Morgan: Non-Music Content Administrator

(She) Karolina Stopar: Marketing & Social Promotion Administrator
   */

  const team = [
    {
      name: "Dariya Zagar",
      role: "Founder & CEO",
      image: "/assets/images/she.svg",
      email: "dariya@distroplay.in",
    },
    {
      name: "Sarah Bushey",
      role: "COO & General Counsel",
      image: "/assets/images/she.svg",
      email: "sarah@distroplay.in",
    },
    {
      name: "Robby Busick",
      role: "Chief Financial Officer",
      image: "/assets/images/he.svg",
      email: "robby@distroplay.in",
    },
    {
      name: "Lochin T.",
      role: "Chief Technical Officer",
      image: "/assets/images/he.svg",
      email: "lochin@distroplay.in",
    },
    {
      name: "Mandy Agor",
      role: "Director of Business Development & Global Operations",
      image: "/assets/images/she.svg",
      email: "mandy@distroplay.in",
    },
    {
      name: "Joe Jeriff",
      role: "Head of Business Partnership & Negotiation",
      image: "/assets/images/he.svg",
      email: "joe@distroplay.in",
    },
    {
      name: "Gregoria Aguilar",
      role: "SAARC Business Development Manager",
      image: "/assets/images/she.svg",
      email: "gregoria@distroplay.in",
    },
    {
      name: "Pavan Yeldi",
      role: "Regional Manager & Country Head - India",
      image: "/assets/images/he.svg",
      email: "pyeldi@distroplay.in",
    },
    {
      name: "Sageer Perumayil",
      role: "Director of Sales & Distribution Operations",
      image: "/assets/images/he.svg",
      email: "sageer@distroplay.in",
    },
    {
      name: "Leon Bardole",
      role: "Support Specialist & Label Manager",
      image: "/assets/images/he.svg",
      email: "leon@distroplay.in",
    },
    {
      name: "Ana Kapanadze",
      role: "Artist & Label Account Manager",
      image: "/assets/images/she.svg",
      email: "ana@distroplay.in",
    },
    {
      name: "Baris Gurbuz",
      role: "Client Relationship & Onboarding Manager",
      image: "/assets/images/he.svg",
      email: "baris@distroplay.in",
    },
    {
      name: "Mathew Phile",
      role: "Head of Trust & Safety Operation",
      image: "/assets/images/he.svg",
      email: "mathew@distroplay.in",
    },
    {
      name: "Viktoria Parvadova",
      role: "Royalty & Reporting Administrator",
      image: "/assets/images/she.svg",
      email: "viktoria@distroplay.in",
    },
    {
      name: "Natalia Sokolenko",
      role: "Director of Right Management & YouTube Administrator",
      image: "/assets/images/she.svg",
      email: "natalia@distroplay.in",
    },
    {
      name: "Olha Havrylenko",
      role: "Non-Music Content Administrator",
      image: "/assets/images/she.svg",
      email: "olha@distroplay.in",
    },
    {
      name: "Katerina Parvadova",
      role: "Marketing & Social Promotion Administrator",
      image: "/assets/images/she.svg",
      email: "katerina@distroplay.in",
    },
    {
      name: "Esther Owu",
      role: "General Support & Editorial Administrator",
      image: "/assets/images/she.svg",
      email: "esther@distroplay.in",
    },
  ];

  return (
    <Box
      w="100%"
      //linear gradient to down
      bgGradient="linear(to-b ,#F9F5ED, #FFFFFF)"
      minH={["70vh", "70vh", "100vh", "100vh"]}
      display="flex"
      flexDir="column"
      justifyContent={["flex-start", "flex-start", "center", "center"]}
      alignItems="flex-start"
      p={[4, 8, 12, 16]}
      color="block1.base"
    >
      <HStack mt={"20vh"}>
        <AnimatedHeading
          color="block2.base"
          fontFamily="Giselle"
          fontSize={[58, 68, 80, 100]}
          fontWeight="bold"
          textAlign="left"
        >
          About us
        </AnimatedHeading>
      </HStack>

      <VStack
        w="100%"
        mt={20}
        display={["none", "none", "flex", "flex"]}
        spacing={12}
      >
        <ServiceLeft
          title="Who We Are ? "
          text="DistroPlay is a leading music distributor and media company that has propelled
countless artists, musicians, and YouTube creators to new heights in the music and
media industries. With over six years of deep expertise in music distribution and
YouTube content management, we are uniquely equipped to expand your reach and
significantly boost your revenue. Our unmatched leadership in distribution, right
management and monetization for independent musicians and creators sets us apart
as the industry’s premier choice. At DistroPlay, we offer a dynamic and rewarding
career environment, where you’ll work alongside brilliant minds, innovative thinkers,
and have access to unparalleled opportunities. Make DistroPlay your professional
home and join us in shaping the future of music and media."
          image="./assets/images/who.svg"
        />
        <ServiceRight
          title="Purpose"
          text="DistroPlay is driven by a core mission of transparency and delivering a highly
personalized approach that is dedicated to advancing the careers of artists, record
labels, managers, and more. We are committed to providing tailored solutions and
essential tools designed to foster growth and success in an ever-evolving music
industry. As traditional music companies struggle to keep pace with industry changes,
DistroPlay stands out by breaking the mold and setting new standards for innovation
and client support. Our forward-thinking approach ensures that our clients are not only
equipped to thrive but are also positioned at the forefront of the music industry’s
transformation."
          image="./assets/images/purpose.svg"
        />
      </VStack>
      <VStack
        w="100%"
        mt={20}
        display={["flex", "flex", "none", "none"]}
        spacing={12}
      >
        <ServiceLeft
          title="Who We Are ? "
          text="DistroPlay is a leading music distributor and media company that has propelled
countless artists, musicians, and YouTube creators to new heights in the music and
media industries. With over six years of deep expertise in music distribution and
YouTube content management, we are uniquely equipped to expand your reach and
significantly boost your revenue. Our unmatched leadership in distribution, right
management and monetization for independent musicians and creators sets us apart
as the industry’s premier choice. At DistroPlay, we offer a dynamic and rewarding
career environment, where you’ll work alongside brilliant minds, innovative thinkers,
and have access to unparalleled opportunities. Make DistroPlay your professional
home and join us in shaping the future of music and media."
          image="./assets/images/who.svg"
        />
        <ServiceLeft
          title="Purpose"
          text="DistroPlay is driven by a core mission of transparency and delivering a highly
personalized approach that is dedicated to advancing the careers of artists, record
labels, managers, and more. We are committed to providing tailored solutions and
essential tools designed to foster growth and success in an ever-evolving music
industry. As traditional music companies struggle to keep pace with industry changes,
DistroPlay stands out by breaking the mold and setting new standards for innovation
and client support. Our forward-thinking approach ensures that our clients are not only
equipped to thrive but are also positioned at the forefront of the music industry’s
transformation."
          image="./assets/images/purpose.svg"
        />
      </VStack>
      <VStack w="100%" mt={20}>
        <AnimatedHeading
          color="block3.base"
          fontFamily="Giselle"
          fontSize={[34, 44, 54, 64]}
          fontWeight="bold"
          textAlign="left"
        >
          Our Team
        </AnimatedHeading>
        <Text
          textAlign={"center"}
          fontSize={16}
          color="textPrimary.body"
          fontFamily="Gilroy"
        >
          The DistroPlay team consists of experienced music professionals, sharp
          marketers, skilled engineers, and creative storytellers. We have
          extensive knowledge of the global music industry and have worked with
          a wide variety of artists and labels. We understand the current
          landscape and the tools needed for artists and labels to grow without
          limits.
          <br></br>
          <br></br>
          By blending culture, storytelling, and technology, DistroPlay leads
          the way in helping artists and labels succeed at every stage of their
          careers, from emerging talents to established icons. At DistroPlay,
          we're a team of culture catalysts and we have one core belief:
          independence is essential not just for artists, but for the world at
          large.
        </Text>
        <SimpleGrid
          w="100%"
          columns={[2, 2, 3, 3]}
          align="center"
          spacing={10}
          pt={10}
          alignContent="center"
          alignItems={"center"}
          //centerContent
          justifyContent="center"
          justifyItems={"center"}
          verticalAlign="center"
        >
          {team.map((member, index) => (
            <TeamMember
              key={index}
              name={member.name}
              role={member.role}
              image={`./assets/images/team/${index + 1}.png`}
              email={member.email}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default function ServicesScreen({ isLargerThan800 }) {
  return (
    <Box w="100%" h="100vh" bg="#F9F5ED">
      {!isLargerThan800 ? (
        <div>
          <Hero />
          <Footer isLargerThan800={isLargerThan800} />
        </div>
      ) : (
        <SmoothScroll>
          <Hero />
          <Footer isLargerThan800={isLargerThan800} />
        </SmoothScroll>
      )}
    </Box>
  );
}
