import {
  Box,
  Circle,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Square,
  Text,
  theme,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import animDistro1 from "../anim/anim_distro1.json";
import animDistro2 from "../anim/anim_distro2.json";
import animDistro3 from "../anim/anim_distro3.json";
import { AnimatedHeading } from "../components/Animated";
import { TypingAnimation } from "../components/Animated";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import Footer from "../components/Footer";
import { useInView } from "react-hook-inview";
import useScrollPercentage from "../hooks/useScrollPercentage";
import { useRef } from "react";

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
const Hero = () => {
  const [clicked, setClicked] = useState(0);
  useEffect(() => {
    if (clicked > 4) {
      openInNewTab("https://vishesh.dev/");
    }
  }, [clicked]);
  const [scrollPosition, setScrollPosition] = useState(0);

  return (
    <Box
      w="100%"
      minH={["70vh", "70vh", "90vh", "90vh"]}
      display="flex"
      flexDir="column"
      justifyContent={["flex-start", "flex-start", "center", "center"]}
      alignItems="flex-start"
      p={[4, 8, 12, 16]}
      color="block1.base"
      bg="bgPrimary.base"
    >
      <HStack
        mt={"20vh"}

        // onClick={
        //   setClicked(click => click+1)
        // }
      >
        <AnimatedHeading
          color="textPrimary.heading"
          fontFamily="Giselle"
          fontSize={[55, 88, 110, 140]}
          fontWeight="bold"
          textAlign="left"
        >
          Distribution
        </AnimatedHeading>
        <Image
          src="/assets/images/is.svg"
          w={["20px", "30px", "40px", "50px"]}
        />
      </HStack>

      <HStack mt={-5}>
        <Image
          src="/assets/images/note.svg"
          w={["30px", "50px", "80px", "100px"]}
        />
        <TypingAnimation
          color="textPrimary.heading"
          fontFamily="Giselle"
          fontSize={[50, 88, 110, 140]}
          fontWeight="bold"
          textAlign="left"
        >
          Just the start
        </TypingAnimation>
      </HStack>
      <SimpleGrid columns={[1, 1, 1, 1]} p={[4, 8, 12, 16]}>
        <Box></Box>
        <Box
          onClick={() => {
            setClicked(clicked + 1);
          }}
        >
          <Text
            color="textPrimary.body"
            fontFamily="Gilroy"
            fontSize={[18, 18, 22, 22]}
            textAlign="justify"
          >
            We’ve transformed the landscape for independent artists and labels
            by creating a more equitable platform. Through our extensive network
            of both direct and non-exclusive sub-distribution partnerships
            across the globe, we are able to customize our approach to precisely
            meet the unique requirements of your releases. This tailored
            strategy ensures that your music reaches the right audience with
            maximum impact.
          </Text>
        </Box>
        <Box></Box>
      </SimpleGrid>
      <Image
        src="/assets/images/arrow.svg"
        w="50px"
        position="absolute"
        top="100vh"
        right="30px"
        style={{
          cursor: "pointer",
        }}
        transform="translateY(-120%)"
        onClick={() => {
          //scroll to next section
        }}
      />
    </Box>
  );
};
const Section2 = ({ scrollPercentage }) => {
  return (
    <Box
      w="100%"
      minH={["50vh", "60vh", "60vh", "60vh"]}
      p={[4, 8, 12, 16]}
      position="relative"
      bg="bgPrimary.base"
    >
      {/* 
      Generate a grid shape with columns and rows based on the screen size (color #00000050)
    */}
      <Wrap
        w="100%"
        justify="center"
        spacing={0}
        position="absolute"
        top={"50%"}
        left={"50%"}
        transform="translate(-50%, -50%)"
      >
        {[...Array(48)].map((e, i) => (
          <WrapItem>
            <Box
              key={i}
              border="1px solid #00000030"
              w={["40px", "50px", "60px", "80px"]}
              h={["40px", "50px", "60px", "80px"]}
            ></Box>
          </WrapItem>
        ))}
      </Wrap>
      {scrollPercentage > 0.06 && scrollPercentage < 0.23 ? (
        <Lottie
          //autoplay={true}

          style={{
            width: "100%",
            height: 800,
          }}
          animationData={animDistro1}
        />
      ) : (
        <Box
          style={{
            width: "100%",
            height: 800,
          }}
        ></Box>
      )}
    </Box>
  );
};
const Section3 = () => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  return (
    <Box
      w="100%"
      minH={["60vh", "60vh", "100vh", "100vh"]}
      bg="bgSecondary.base"
      p={[8, 8, 12, 16]}
      display="flex"
    >
      <VStack w="100%" alignItems={"flex-start"} spacing={[0, 0, 0, 40]}>
        <VStack>
          <AnimatedHeading
            color="textSecondary.base"
            fontFamily="Giselle"
            mt={10}
            fontSize={[60, 60, 60, 60]}
            textAlign="left"
            //break in 2 lines
            w={["100%", "100%", "100%", "100%"]}
          >
            One Stop Digital Solution
          </AnimatedHeading>
          <AnimatedHeading
            color="textSecondary.base"
            fontFamily="Giselle"
            mb={10}
            fontSize={[44, 44, 40, 40]}
            textAlign="left"
            opacity={0.75}
            //break in 2 lines
            w={["100%", "100%", "100%", "100%"]}
          >
            Our tech and expertise will make your music heard.
          </AnimatedHeading>
        </VStack>
        <HStack spacing={[10, 20, 40, 40]} w="100%" alignItems="flex-start">
          {isLargerThan800 && (
            <Circle size={[null, null, "50px", "100px"]} bg="block2.base" />
          )}
          <Box
            w={["100%", "100%", "100%", "100%"]}
            display="flex"
            bg="white"
            justifyContent={"center"}
            //bottom right corner
            borderRadius="0 0 50% 0px"
          >
            <Lottie
              autoplay={true}
              animationData={animDistro2}
              style={{
                width: "80%",
                height: "100%",
                transform: "translateY(-30%)",
              }}
            />
          </Box>
        </HStack>
        <SimpleGrid
          mt={20}
          w="100%"
          minChildWidth={["100%", "100%", "30%", "30%"]}
          spacing="50px"
        >
          <Box style={{ marginLeft: "260px", marginRight: "-20px" }}>
            <AnimatedHeading
              color="textSecondary.base"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              As pioneers in online streaming for media and entertainment, we
              offer a comprehensive end-to-end solution designed to empower the
              next generation of music creators, labels, and publishers. Our
              extensive network spans over 200 stores worldwide, with continuous
              growth and expansion in our portfolio. This ensures your music not
              only reaches a vast global audience but also maximizes its
              potential impact in the ever-evolving digital landscape.
            </AnimatedHeading>
          </Box>
          <Box style={{ marginLeft: "100px", marginRight: "120px" }}>
            <AnimatedHeading
              color="textSecondary.base"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              Our cutting-edge technology delivers a holistic digital
              infrastructure that revolutionizes audio and video distribution.
              We offer robust content protection, advanced rights management,
              and efficient publishing administration, all within a single,
              user-friendly platform. Additionally, our sophisticated data
              tracking and analytics tools provide deep insights, empowering you
              to optimize your media strategy and maximize your content’s
              performance across all channels.
            </AnimatedHeading>
          </Box>
        </SimpleGrid>
      </VStack>
    </Box>
  );
};
const Section4Principles = (props) => {
  return (
    <Box w="100%" px={[4, 8, 12, 16]}>
      <SimpleGrid
        w="100%"
        minChildWidth={["100%", "100%", "50%", "40%"]}
        spacing="20px"
      >
        <HStack
          w="100%"
          pos={"relative"}
          spacing={[10, 10, 20, 20]}
          alignItems={"flex-start"}
        >
          <Image
            src={`/assets/images/${props.svg}.svg`}
            w={[10, 10, 20, 20]}
            h={[10, 10, 20, 20]}
          ></Image>
          <Heading
            textAlign={"left"}
            fontSize={[28, 28, 32, 32]}
            color="textPrimary.heading"
            fontFamily="Giselle"
          >
            {props.title}
          </Heading>
        </HStack>
        <Box
          w="100%"
          display={"flex"}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Text
            textAlign={"justify"}
            fontSize={[20, 20, 20, 16]}
            color="textPrimary.body"
            fontFamily="Gilroy"
          >
            {props.text}
          </Text>
        </Box>
      </SimpleGrid>
      <Divider orientation="horizontal" mt={8} borderColor="#00000040" />
    </Box>
  );
};
const Section4 = () => {
  return (
    <Box w="100%" minH="100vh" bg="#FFFEFC">
      <VStack
        w="100%"
        display={"flex"}
        alignItems={"flex-end"}
        p={[10, 10, 12, 16]}
        spacing={[10, 10, 12, 16]}
      >
        <HStack w={["100%", "100%"]} my="0vh" spacing={[10, 10, 10, 10]}>
          <Image src={"/assets/images/note.svg"} w={100} />
          <AnimatedHeading
            color="textPrimary.heading"
            fontFamily="Giselle"
            fontSize={48}
            textAlign="left"
          >
            High Quality Music That Makes A Difference.
          </AnimatedHeading>
        </HStack>
        <Text
          color="textPrimary.body"
          fontFamily="Gilroy"
          fontSize={[18, 18, 22, 22]}
          textAlign="left"
        >
          We’re committed to delivering music that stands out and makes a
          lasting impact. Our focus is on top-noch sound quality and strategic
          placement, ensuring your music connects deeply with listeners and
          leaves a memorable impression.
        </Text>
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={5} w="100%">
          <Box w="100%" p={10} bg="#DAD7D350">
            <Lottie
              autoplay={true}
              style={{
                width: "100%",
                height: "100%",
              }}
              animationData={animDistro3}
            />
          </Box>
          <VStack w="100%" display={"flex"} alignItems={"flex-start"}>
            <AnimatedHeading
              color="block2.base"
              fontFamily="Giselle"
              fontSize={40}
              textAlign="left"
            >
              Consistent Branding
            </AnimatedHeading>
            <Text
              color="textPrimary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              To build a strong and recognizable presence, keep your social
              media active and regularly engage with your audience. Consistent
              posting and meaningful interactions help reinforce your presence
              and build a loyal community.
            </Text>
            <Divider
              orientation="horizontal"
              pt={5}
              pb={5}
              borderColor="#00000040"
            />
            <AnimatedHeading
              color="block2.base"
              fontFamily="Giselle"
              fontSize={40}
              textAlign="left"
              pt={5}
            >
              Fan Engagement
            </AnimatedHeading>
            <Text
              color="textPrimary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              Maintaining meaningful contact with your fans is crucial. Keep
              them informed about important updates like tour dates and new
              album releases. Instead of focusing on follower count, we value
              real interaction and engagement within your community for a more
              authentic connection.
            </Text>
          </VStack>
        </SimpleGrid>
        <Divider
          orientation="horizontal"
          pt={0}
          pb={10}
          borderColor="#00000040"
        />
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <AnimatedHeading
            color="textPrimary.heading"
            fontFamily="Giselle"
            fontSize={56}
            textAlign="center"
          >
            Transform Your Music Journey With Us
          </AnimatedHeading>
        </div>
        <SimpleGrid columns={[1, 1, 2, 2]} pt={0} spacing={10} w="100%">
          <VStack w="100%" display={"flex"} alignItems={"flex-start"}>
            <AnimatedHeading
              color="block2.base"
              fontFamily="Giselle"
              fontSize={40}
              textAlign="left"
            >
              Submit an Application
            </AnimatedHeading>
            <Text
              color="textPrimary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              We welcome artists and labels from every genre to take advantage
              of our premium services. By submitting your application, you open
              the door to a world of opportunities tailored to elevate your
              music career.
            </Text>
            <Divider
              orientation="horizontal"
              pt={5}
              pb={5}
              borderColor="#00000040"
            />

            <AnimatedHeading
              color="block2.base"
              fontFamily="Giselle"
              fontSize={40}
              textAlign="left"
              pt={5}
            >
              We Review
            </AnimatedHeading>
            <Text
              color="textPrimary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              Our team will conduct a detailed review of your application, and
              one of our support executives will reach out to gather any
              additional information and guide you through the next steps to
              complete your onboarding.
            </Text>
            <Divider
              orientation="horizontal"
              pt={5}
              pb={5}
              borderColor="#00000040"
            />
            <AnimatedHeading
              color="block2.base"
              fontFamily="Giselle"
              fontSize={40}
              textAlign="left"
              pt={5}
            >
              Get Access
            </AnimatedHeading>
            <Text
              color="textPrimary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 16, 16]}
              textAlign="justify"
              duration={0.5}
              delay={0.01}
            >
              Once Onboarded, You gain full access to our cutting-edge
              technology and comprehensive suite of tools, empowering you to
              expand your music business on a global scale.
            </Text>
          </VStack>
          <Box w="100%" p={10} bg="#DAD7D350">
            <Image src={"/assets/images/review.svg"} w="100%" h="100%" />
          </Box>
        </SimpleGrid>
      </VStack>
    </Box>
  );
};
const Section5 = ({ isLargerThan800 }) => {
  return (
    <Box w="100%" minH="100vh" bg="#FFFEFC">
      <VStack
        w="100%"
        display={"flex"}
        alignItems={"flex-start"}
        p={[10, 10, 12, 16]}
        spacing={[10, 10, 12, 16]}
      >
        <VStack spacing={-5} alignItems={"flex-start"}>
          <AnimatedHeading
            color="textPrimary.heading"
            fontFamily="Giselle"
            fontSize={56}
            textAlign="left"
          >
            Principles
          </AnimatedHeading>
          <AnimatedHeading
            color="block3.base"
            fontFamily="Giselle"
            fontSize={48}
            textAlign="left"
          >
            which guide us
          </AnimatedHeading>
        </VStack>
        <Section4Principles
          title="Our Clients Needs are Paramount"
          text="We place the utmost importance on
understanding and addressing your unique needs. Our services are carefully
designed to meet your goals and ensure your satisfaction is our top priority."
          svg={"p1"}
        />
        <Section4Principles
          title="Stay Open and Learn from Mistakes"
          text="We see mistakes as opportunities to grow. By
listening to feedback, adjusting our approach, and working together, we improve our
services and achieve better results."
          svg={"p2"}
        />
        <Section4Principles
          title="Design for Accessibility Always"
          text="We are committed to creating designs that are
easy to use and accessible for everyone. We focus on improving usability and
ensuring our tools are universally available."
          svg={"p3"}
        />
        <Section4Principles
          title="Innovate to Improve Outcomes"
          text="We focus on new ideas and technologies to
achieve the best results. By constantly innovating, we enhance our services and help
you succeed in the music industry."
          svg={"p4"}
        />
      </VStack>
      <Footer isLargerThan800={isLargerThan800} />
    </Box>
  );
};

export default function HomeScreen() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [scrollPercentage, setScrollPosition] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    if (ref && ref.current) setHeight(ref.current.clientHeight);
  }, [ref]);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        const position = window.scrollY / window.document.body.offsetHeight;
        setScrollPosition(position);
        console.log(position);
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <Box w="100%" h="100vh" bg="#654062">
      {isLargerThan800 ? (
        <SmoothScroll>
          <div>
            <Hero scrollPercentage={scrollPercentage} />
            <Section2 scrollPercentage={scrollPercentage} />
            <Section3 scrollPercentage={scrollPercentage} />
            <Section4 scrollPercentage={scrollPercentage} />
            <Section5
              isLargerThan800={isLargerThan800}
              scrollPercentage={scrollPercentage}
            />
          </div>
        </SmoothScroll>
      ) : (
        <div>
          <Hero />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 isLargerThan800={isLargerThan800} />
        </div>
      )}
    </Box>
  );
}
