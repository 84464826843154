import { HStack, Box, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../App";
import { AnimatedHeading } from "../components/Animated";


const Box2 = {
  initial: {
    width: "100%",
    x: "-80vw",
  },
  
  animate: {
    width: "0%",
    x: "0vw",
    transition: {
      duration: 0.5,
      delay: 0.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exit: {
    width: "100%",
    x: "-80vw",
    transition: {
      duration: 0.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },

  //left
  initialLeft: {
    width: "100%",
    x: "0vw",
  },
  animateLeft: {
    width: "0%",
    x: "0vw",
    transition: {
      duration: 0.8,
      //delay: 0.2,
      ease: [0.87, 0, 0.13, 1],
    },
  },
  exitLeft: {
    width: "100%",
    x: "0vw",
    transition: {
      duration: 1,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};
const Box3 = {
    initial: {
        width: "100%",
        left: "0%",
    },
    animate: {
        width: "10%",
        left: "0%",
        transition: {
            duration: 0.7,
            ease: [0.87, 0, 0.13, 1],
          },
    },

}


const Box1 = {
    initial: {
      width: "100%",
      x: "0vw",
      
      
    },
    animate: {
      width: "10%",
       x : "70vw",
      transition: {
        duration: 1.0,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    animateDirChange: {
       width: "10%",
       x : "0vw",
      transition: {
        duration: 1.0,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        x: "0vw",
        transition: {
            duration: 0.8,
            delay: 0.3,
            ease: [0.87, 0, 0.13, 1],
          },
    },
    exitDirChange: {
        width: "100%",
        x: "-70vw",
        transition: {
            duration: 0.8,
            delay: 0.3,
            ease: [0.87, 0, 0.13, 1],
          },
    },
    initialLeft:{
        width: "10%",
        x: "-0vw",
    },
    animateLeft: {
        width: "10%",
        x: "-0vw",
        transition: {
            duration: 0.8,
            
            ease: [0.87, 0, 0.13, 1],
          },
    },
    exitLeft: {
        width: "10%",
        x: "-0vw",
        transition: {
            duration: 0.8,
            
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };

  function Services(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const [direction, setDirection] = useState(location.state?.direction ? location.state.direction :"right");
    const [nav, setNav] = useState(false);
    const [dirChange, setDirChange] = useState(false);
    const context = useContext(props.LocationContext);
    useEffect(() => {
        console.log("services");
        context.setLocation("services");
    }, [])
    
    
    const onClickHandler = (route) => {
        
        if(route === "/about"){
            if(direction === "right"){
                setDirChange(true);
                setDirection("left");
            }
        }else{
            if(direction === "left"){
                setDirChange(true);
                setDirection("right");
            }
        }
        setTimeout(() => {
            
        setNav(true);

        }, 0);
    }
    useEffect(() => {
        if(nav){
            if(direction === "left"){
                navigate("/about",{ state: { direction: "left" } });
            }else{
                navigate("/home",{ state: { direction: "right" } });
            }
            console.log("direction", direction);
        }
    }, [nav])
    
  return (
    <motion.div className="container">
      <HStack w="100%" spacing={0}>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light red
            background: theme.colors.block3.base,
            position: "fixed",
            top: 0,
            left: 0,
          }}
          initial={direction === "right" ? "" : "initial"}
          animate={direction === "right" ? "" : "animate"}
          exit={direction === "right" ? "" : "exit"}
          //variants={Box3}
          onClick={() => onClickHandler("/about")}
        >
          <Box
            height={"100vh"}
            width={"100%"}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"center"}
            pb={20}
          >
            <AnimatedHeading
              color={"white"}
              fontSize={30}
              fontWeight={"bold"}
              fontFamily={"Giselle"}
              transform={"rotate(-90deg)"}
              textAlign={"left"}
            >
              About
            </AnimatedHeading>
          </Box>
        </motion.div>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            background: theme.colors.block2.base,
            position: "fixed",
            top: 0,
            left: `${direction === "right" ? "90%" : "10%"}`,
          }}
          initial={direction === "right" ? "initial" : "initialLeft"}
          animate={direction === "right" ? "animate" : "animateLeft"}
          exit={direction === "right" ? "exit" : "exitLeft"}
          variants={Box2}
          onClick={() => onClickHandler("/services")}
        ></motion.div>
        <Container minH={"100vh"} width={"80%"}></Container>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light blue
            background: theme.colors.block1.base,
            position: "fixed",
            top: 0,
            left: `${
              direction === "right" ? (dirChange ? "90%" : "20%") : "90%"
            }`,
          }}
          initial={direction === "left" ? "initialLeft" : "initial"}
          animate={
            direction === "left"
              ? "animateLeft"
              : dirChange
              ? "animateDirChange"
              : "animate"
          }
          exit={
            direction === "left"
              ? "exitLeft"
              : dirChange
              ? "exitDirChange"
              : "exit"
          }
          //initial={"initial"}
          //animate={"animate"}
          //exit={"exit"}
          variants={Box1}
          onClick={() => onClickHandler("/home")}
        >
          <Box
              height={"100vh"}
              width={"100%"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              pb={20}
              
            >
              <AnimatedHeading
                color={"white"}
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Giselle"}
                transform={"rotate(-90deg)"}
                textAlign={"left"}
              >
                Home
              </AnimatedHeading>
            </Box>
        </motion.div>
        {direction == "left" && dirChange && (
          <motion.div
            style={{
              width: "10%",
              height: "100vh",
              //light blue
              background: theme.colors.block1.base,
              position: "fixed",
              top: 0,
              left: "90%",
            }}
            onClick={() => onClickHandler("/home")}
          >
            
          </motion.div>
        )}
      </HStack>
    </motion.div>
  );
}

export default Services;

/*
import { HStack, Box, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



const Box2 = {
    initial: {
      width: "100%",
      left: "10%",
      
      
    },
    animate: {
      width: "0%",
      left: "90%",
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        left: "10%",
        transition: {
            duration: 0.5,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };
const Box2Right = {
    initial: {
      width: "100%",
      left: "10%",
      
      
    },
    animate: {
      width: "0%",
      right: "10%",
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        left: "10%",
        transition: {
            duration: 0.5,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };
  const Box2Left = {
    initial: {
        width: "100%",
        right: "10%",
    },
    animate: {
      width: "0%",
      left: "10%",
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        //width: "100%",
        //right: "10%",
        transition: {
            duration: 0.5,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };
const Box1 = {
    initial: {
      width: "100%",
      left: "20%",
      
      
    },
    animate: {
      width: "10%",
      left: "90%",
      transition: {
        duration: 1.0,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        left: "20%",
        transition: {
            duration: 0.8,
            delay: 0.3,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };

function Home() {

    const navigate = useNavigate();
    const [direction, setDirection] = useState("left");
    const [nav, setNav] = useState(false);
    
    const onClickHandler = (route) => {
        if(route === "/about"){
            setDirection("left");
        }else{
            setDirection("right");
        }
        setNav(true);
    }
    useEffect(() => {
        if(nav){
            if(direction === "left"){
                navigate("/about");
            }else{
                navigate("/home");
            }
        }
    }, [direction, nav])
    
  return (
    <motion.div className="container">
      <HStack w="100%" spacing={0}>
      <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light red
            background: "red",
            position: "fixed",
            top: 0,
            left: 0,
          }}
          
          onClick={()=>onClickHandler("/about")}
        >
            
          
        </motion.div>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light yellow
            background: "green",
            position: "fixed",
            top: 0,
            left: "10%",
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={Box2Right}
          //variants={direction !== "left" ? Box2Right : Box2Left}
          
            onClick={()=>onClickHandler("/services")}
        >
            
          
        </motion.div>
        <Container
            minH={"100vh"}
            width={"80%"}
        >
            
        </Container>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light blue
            background: "yellow",
            position: "fixed",
            top: 0,
            right: "0%",
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={Box1}
          
            onClick={()=>onClickHandler("/home")}
        >
            
          
        </motion.div>
      </HStack>
    </motion.div>
  );
}

export default Home;
*/