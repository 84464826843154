import { HStack, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../App";
import { AnimatedHeading } from "../components/Animated";

const Box1 = {
    initial: {
      width: "100%",
      display: "flex",
      
    },
    animate: {
      width: "0%",
      transition: {
        duration: 0.8,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        display: "flex",
        transition: {
            duration: 1,
            ease: [0.87, 0, 0.13, 1],
          },

    }
  };

  const Box2 = {
    initial: {
      width: "10%",
      y: "100vh",
    },
    animate: {
      width: "10%",
        y: "0vh",
      transition: {
        duration: 0.8,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "80%",
        transition: {
            delay: 0.5,
            duration: 0.7,
            ease: [0.87, 0, 0.13, 1],
          },

    }
  };

function Home(props) {
    const navigate = useNavigate();
    const context = useContext(props.LocationContext);
    useEffect(() => {
        context.setLocation("home");
    }, [])
    
    const [altTrigger, setAltTrigger] = useState(false)
    useEffect(() => {
        if(altTrigger){
            navigate("/about",{ state: { direction: "right" } });
        }
    }, [altTrigger])
    //check if page is root in navigation history
    const location = useLocation();
    const[isRoot, setIsRoot] = useState((location?.state?.direction) ? false : true)

      
  return (
    <motion.div className="container">
      <HStack w="100%" spacing={0}>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light red
            background: theme.colors.block3.base,
            position: "fixed",
            top: 0,
            left: 0,
          }}
          onClick={() => {
            setAltTrigger(true);
          }}
        >
          <Box
              height={"100vh"}
              width={"100%"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              pb={20}
              
            >
              <AnimatedHeading
                color={"white"}
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Giselle"}
                transform={"rotate(-90deg)"}
                textAlign={"left"}
              >
                About
              </AnimatedHeading>
            </Box>
        </motion.div>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
           
            background: theme.colors.block2.base,
            position: "fixed",
            top: 0,
            left: "10%",
          }}
          
          onClick={() =>
            navigate("/services", { state: { direction: "right" } })
          }
        >
           <Box
              height={"100vh"}
              width={"100%"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              pb={20}
              
            >
              <AnimatedHeading
                color={"white"}
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Giselle"}
                transform={"rotate(-90deg)"}
                textAlign={"left"}
              >
                Services
              </AnimatedHeading>
            </Box>
        </motion.div>
        {
            altTrigger && <motion.div
            style={{
              width: "10%",
              height: "100vh",
              //light yellow
              background: theme.colors.block2.base,
              position: "fixed",
              top: 0,
              left: "10%",
              zIndex: 1000
            }}
            initial={"initial"}
            animate={"animate"}
            exit={"exit"}
            variants={Box2}
            onClick={() =>
              navigate("/services", { state: { direction: "right" } })
            }
          >
           
          </motion.div>
        }
        <motion.div
          style={{
            width: "0%",
            height: "100vh",
            //light blue
            background: theme.colors.block1.base,
            position: "fixed",
            top: 0,
            left: "20%",
          }}
          initial={isRoot ? "" : "initial"}
          animate="animate"
          exit="exit"
          variants={Box1}
          onClick={() => navigate("/home")}
        ></motion.div>
      </HStack>
    </motion.div>
  );
}

export default Home;