import {
  Box,
  Button,
  Circle,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Square,
  Text,
  theme,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import animDistro1 from "../anim/anim_distro1.json";
import animDistro2 from "../anim/anim_distro2.json";
import { AnimatedHeading } from "../components/Animated";
import { TypingAnimation } from "../components/Animated";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";

const IconContainer = (props) => {
  // A round container with Image in center that rotates 45deg on hover
  return (
    <Circle
      size={props.size}
      bg="white"
      pos="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      transition="all 0.2s ease-in-out"
      _hover={{
        transform: "rotate(45deg)",
      }}
    >
      <Image src={props.src} alt={props.alt} w={props.w} />
    </Circle>
  );
};

const handleSendEmail = () => {
  const subject = encodeURIComponent("Contact Request");
  const body = encodeURIComponent(
    "Hello, \n\nPlease find the contact details below:\n\n"
    // You can append any data like name, role, email here dynamically.
  );
  const recipient = "recipient@example.com"; // Replace with actual recipient

  window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
};

export default ({ isLargerThan800 }) => {
  return (
    <Box w="100%" bg="#654062" zIndex={100} pb={0}  >
      <SimpleGrid columns={[1, 1, 2, 2]} spacing={10}>
        <VStack
          spacing={10}
          w="100%"
          alignItems="flex-start"
          px={[8, 8, 12, 16]}
          py={[20]}
          bg={`${!isLargerThan800 ? "block3.base" : "transparent"}`}
          borderBottomEndRadius={isLargerThan800 ? 0 : 100}
        >
          <AnimatedHeading
            fontFamily="Giselle"
            fontSize={[34, 34, 45, 45]}
            fontWeight="bold"
            textAlign="left"
            color="white"
            lineHeight="1"
          >
            Want our help with something?
          </AnimatedHeading>
          <Button
            p={[8, 8, 12, 12]}
            h={[16, 16, 24, 24]}
            variant="outline"
            fontFamily={"Gilroy"}
            color="white"
            borderColor="white"
            borderRadius={100}
            fontWeight="light"
            pos={"relative"}
            fontSize={[20, 20, 24, 24]}
            display="flex"
            justifyContent={"flex-end"}
            _hover={{
              bg: "white",
              color: "block3.base",
            }}
            onClick={handleSendEmail}
          >
            Reach out
            <Circle
              size={[16, 16, 24, 24]}
              ml={"auto"}
              pos={"absolute"}
              right={0}
              bg="white"
            >
              <Image
                src="./assets/images/mail.svg"
                alt="mail"
                w={[8, 8, 12, 12]}
              />
            </Circle>
            <Circle
              size={[16, 16, 24, 24]}
              ml={"auto"}
              visibility="hidden"
              bg="white"
            >
              <Image
                src="./assets/images/mail.svg"
                alt="mail"
                w={[8, 8, 10, 10]}
              />
            </Circle>
          </Button>
        </VStack>
        <VStack
          spacing={10}
          w="100%"
          display={"flex"}
          alignItems="flex-start"
          px={[8, 8, 12, 16]}
          py={[14]}
        >
          <HStack w={"100%"} spacing={10}>
            <VStack spacing={0} display={"flex"} alignItems="flex-start">
              <Text
                color="#ffffff50"
                fontFamily="Gilroy"
                fontSize={[13, 13, 13, 20]}
                textAlign="left"
                pb={4}
              >
                CONTACT
              </Text>
              <Text
                color="#ffffff"
                fontFamily="Gilroy"
                fontSize={[15, 15, 15, 25]}
                textAlign="left"
                fontWeight={600}
              >
                <a href="mailto:support@distroplay.in">support@distroplay.in</a>
              </Text>
              <Text
                color="#ffffff"
                fontFamily="Gilroy"
                fontSize={[15, 15, 15, 25]}
                textAlign="left"
                fontWeight={600}
              >
                +1 (725) 206-7614
              </Text>
            </VStack>

            <VStack spacing={0} display={"flex"} alignItems="flex-start">
              <Text
                color="#ffffff50"
                fontFamily="Gilroy"
                fontSize={[13, 13, 13, 20]}
                textAlign="left"
                pb={4}
              >
                PAGES
              </Text>
              <Button
                variant="link"
                onClick={() => {
                  window.location.href = "/home";
                }}
              >
                <Text
                  color="#ffffff"
                  fontFamily="Gilroy"
                  fontSize={[15, 15, 15, 25]}
                  textAlign="left"
                  fontWeight={600}
                >
                  Home
                </Text>
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  window.location.href = "/services";
                }}
              >
                <Text
                  color="#ffffff"
                  fontFamily="Gilroy"
                  fontSize={[15, 15, 15, 25]}
                  textAlign="left"
                  fontWeight={600}
                  cursor="pointer"
                >
                  Services
                </Text>
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  window.location.href = "/about";
                }}
              >
                <Text
                  color="#ffffff"
                  fontFamily="Gilroy"
                  fontSize={[15, 15, 15, 25]}
                  textAlign="left"
                  fontWeight={600}
                  cursor="pointer"
                >
                  About
                </Text>
              </Button>
            </VStack>
          </HStack>
        </VStack>
      </SimpleGrid>
      <VStack style={{ paddingTop: "40px" }}>
        <HStack
          w="100%"
          px={[8, 8, 12, 16]}
          justifyContent="space-between"
          alignItems="center"
          py={4}
        >
          <Image
            src="./assets/images/logo-img.png"
            alt="logo"
            w={[150, 150, 200, 200]}
          />
          <HStack>
            <a href={"https://x.com/distro_play"}>
              <IconContainer
                src="https://www.svgrepo.com/show/315268/twitter.svg"
                alt="twitter"
                w={[7, 7, 7, 7]}
                size={[12, 12, 12, 12]}
              />
            </a>
            <a href={"https://linkedin.com/company/distroplay"}>
              <IconContainer
                src="https://www.svgrepo.com/show/315300/linkedin.svg"
                alt="linkedin"
                w={[7, 7, 7, 7]}
                size={[12, 12, 12, 12]}
              />
            </a>
            <a href="https://www.instagram.com/distroplay">
              <IconContainer
                src="https://www.svgrepo.com/show/347063/instagram.svg"
                alt="instagram"
                w={[7, 7, 7, 7]}
                size={[12, 12, 12, 12]}
              />
            </a>
            <a href="https://youtube.com/@distroplay">
              <IconContainer
                src="https://www.iconpacks.net/icons/3/free-icon-youtube-logo-7128.png"
                alt="instagram"
                w={[7, 7, 7, 7]}
                size={[12, 12, 12, 12]}
              />
            </a>
            <a href="https://www.facebook.com/distroplay.music">
              <IconContainer
                src="https://www.iconpacks.net/icons/2/free-icon-facebook-5215.png"
                alt="instagram"
                w={[7, 7, 7, 7]}
                size={[12, 12, 12, 12]}
              />
            </a>
          </HStack>
        </HStack>
        <Text
          color="#ffffff"
          fontFamily="Gilroy"
          fontSize={[13, 13, 13, 14]}
          textAlign="left"
          pb={4}
          w="100%"
          px={[8, 8, 12, 16]}
        >
          © 2024 DistroPlay LLC. All rights reserved.
          <br />
          <br />
          DISTROPLAY LLC is registered in the State of Texas, USA, SOS
          File No 0804875085. <br /> Registered Office: 5900 Balcones Drive,
          Suite 100, Austin, TX 78731
        </Text>
      </VStack>
    </Box>
  );
};
