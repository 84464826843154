import {
  Box,
  Button,
  Circle,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Square,
  Text,
  theme,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import animDistro1 from "../anim/anim_distro1.json";
import animDistro2 from "../anim/anim_distro2.json";
import { AnimatedHeading } from "../components/Animated";
import { TypingAnimation } from "../components/Animated";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import Footer from "../components/Footer";
const ServiceLeft = (props) => {
  return (
    <Box w="100%" px={[4, 8, 12, 16]}>
      <SimpleGrid
        w="100%"
        minChildWidth={["100%", "100%", "50%", "40%"]}
        spacing={40}
      >
        <Image
          src={props.image}
          alt={""}
          width={"100%"}
          height={"100%"}
          objectFit="contain"
          borderRadius={"sm"}
          overflow="hidden"
        />
        <VStack
          w="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <AnimatedHeading
            textAlign={"left"}
            fontSize={[52, 52, 56, 56]}
            color="textPrimary.heading"
            fontFamily="Giselle"
          >
            {props.title}
          </AnimatedHeading>
          <Text
            textAlign={"justify"}
            fontSize={16}
            color="textPrimary.body"
            fontFamily="Gilroy"
          >
            {props.text}
          </Text>
        </VStack>
      </SimpleGrid>
      <Divider orientation="horizontal" mt={8} borderColor="#00000040" />
    </Box>
  );
};
const ServiceRight = (props) => {
  return (
    <Box w="100%" px={[4, 8, 12, 16]}>
      <SimpleGrid
        w="100%"
        minChildWidth={["100%", "100%", "50%", "40%"]}
        spacing={40}
      >
        <VStack
          w="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <AnimatedHeading
            textAlign={"left"}
            fontSize={[52, 52, 56, 56]}
            color="textPrimary.heading"
            fontFamily="Giselle"
          >
            {props.title}
          </AnimatedHeading>
          <Text
            textAlign={"justify"}
            fontSize={16}
            color="textPrimary.body"
            fontFamily="Gilroy"
          >
            {props.text}
          </Text>
        </VStack>
        <Image
          src={props.image}
          alt={""}
          width={"100%"}
          height={"100%"}
          objectFit="contain"
          borderRadius={"sm"}
          overflow="hidden"
        />
      </SimpleGrid>
      <Divider orientation="horizontal" mt={8} borderColor="#00000040" />
    </Box>
  );
};

const Hero = ({ isLargerThan800 }) => {
  return (
    <Box
      w="100%"
      minH={["300vh", "300vh", "660vh", "660vh"]}
      display="flex"
      flexDir="column"
      justifyContent={["flex-start", "flex-start", "center", "center"]}
      alignItems="flex-start"
      color="block1.base"
      bgGradient="linear(to-b ,#F9F5ED, #FFFFFF)"
    >
      <VStack mt={"20vh"} p={[4, 8, 12, 16]} alignItems="flex-start">
        <VStack spacing={-12} alignItems="flex-start">
          <AnimatedHeading
            color="textPrimary.heading"
            fontFamily="Giselle"
            fontSize={[58, 68, 80, 100]}
            fontWeight="bold"
            textAlign="left"
          >
            Services
          </AnimatedHeading>

          <AnimatedHeading
            fontFamily="Giselle"
            fontSize={[48, 58, 80, 80]}
            fontWeight="bold"
            textAlign="left"
            mt={[-10, -10, -20, -20]}
            color="block3.base"
            lineHeight="1"
          >
            tailored to your needs
          </AnimatedHeading>
        </VStack>
        <Text
          color="textPrimary.body"
          fontFamily="Gilroy"
          fontSize={[18, 18, 16, 16]}
          textAlign="justify"
          mt={10}
        >
          We offer customized services to help you with distribution,
          monetization, publishing, and promoting your music. <br /> Each
          service is powerful on its own, but using them together provides even
          greater benefits, <br /> helping you to achieve broader exposure and
          higher earnings for your music.
        </Text>
      </VStack>
      <VStack
        w="100%"
        display={["none", "none", "flex", "flex"]}
        spacing={0}
        p={[4, 8, 12, 16]}
      >
        <ServiceLeft
          title="Digital Distribution"
          text="Expand your reach and elevate your music's presence globally with our advanced
digital distribution services. We expertly deliver your tracks and videos to an extensive
network of digital platforms and stores across the globe. Our all-inclusive solution
covers every aspect of digital distribution, ensuring your content reaches and engages
listeners across the globe with unparalleled efficiency."
          image="./assets/images/services/music-distribution.png"
        />
        <ServiceRight
          title="Right Management"
          text="Unlock the full potential of your music and ensure you capture every cent of your
earnings with our comprehensive Rights Management. We provide meticulous
Publishing Administration, oversee Digital Performance Collection, and manage
Neighbouring Rights, ensuring you never miss out on any royalties. Our expert team
is dedicated to maximizing your revenue and managing your rights efficiently in the
complex landscape of music rights, so you can focus on what you do best for creating music."
          image="./assets/images/services/Right-Management.png"
        />
        <ServiceLeft
          title="Music Monetization"
          text="DistroPlay transforms the complexities of music monetization into a seamless
process. We provide immediate protection for your music content through YouTube's
Content ID and Facebook's Rights Manager, ensuring that every repost and fan made
video contribute to your potential revenue. We strategically maximize your earnings by
monetizing your music across leading user-generated content platforms, including
YouTube, TikTok, Facebook, Instagram, and SoundCloud. With our advanced solutions,
you can seamlessly capitalize on every opportunity and drive revenue growth from your music
content."
          image="./assets/images/services/Music Monetization.png"
        />
        <ServiceRight
          title="Promotion & Marketing"
          text="Soar to the top of the charts and elevate your music to new heights. We offer a full spectrum
of high-impact strategies, including advanced social media optimization, strategic playlist
placements, and innovative digital marketing. Our services extend to dynamic branding,
bespoke creative design, meticulously crafted release packages, and targeted advertising
campaigns. Each service is designed to maximize your reach, elevate your music’s visibility,
engage your audience, and drive your success in the competitive music industry."
          image="./assets/images/services/Marketing & Playlist Pitching.png"
        />

        <ServiceLeft
          title="Powerful Dashboard"
          text="Experience unparalleled control and insight with our advanced Dashboard. This
intuitive, user-friendly interface provides comprehensive streaming analytics,
streamlined royalty payment tracking, and detailed reporting to keep you informed
about your earnings. Effortlessly manage and transfer your music catalogue with our efficient solutions, all within a single platform. Our dashboard is designed to simplify
and optimize your music management, giving you clear visibility and complete
oversight over your revenue and assets."
          image="./assets/images/services/Powerful Dashboard.png"
        />
        <ServiceRight
          title="24/7 Full Support"
          text="Experience unparalleled support with our dedicated client service team, available
around the clock. We guarantee a response to every email within 24 hours, ensuring
that any issues you encounter are swiftly addressed. This seamless support structure
ensures that you’re never held back from advancing your career, allowing you to focus
on growth and success without delays or disruptions."
          image="./assets/images/services/24x7 Dedicated Support.png"
        />
        <ServiceLeft
          title="Artist Profile Verification"
          text="Elevate your professional presence and gain the recognition you deserve. We specialize in
securing verified status across prominent platforms, including YouTube Official Artist
Channels, Spotify, Apple Music, Instagram/Facebook, and more. Our dedicated team guides
you through the verification process, enhancing your professional presence and ensuring you
stand out in a crowded digital landscape. With our support, you gain the recognition and trust
that can propel your career to new heights."
          image="./assets/images/services/Artist Profile Verification.png"
        />
      </VStack>
      <VStack
        w="100%"
        mt={0}
        display={["flex", "flex", "none", "none"]}
        spacing={0}
      >
        <ServiceLeft
          title="Digital Distribution"
          text="Distribute your music worldwide using our digital distribution services. We deliver music and videos to digital stores all over the world, providing you with the ultimate solution to fulfill all digital distribution requirements."
          image="./assets/images/services/rm.jpg"
        />
        <ServiceLeft
          title="Right Management"
          text="Expand your music’s potential while collecting all the money of your rights. We offer Publishing Administration, Digital Performance Collection and Neighbouring Rights, so that you’re not missing out on your royalties."
          image="./assets/images/services/rm.jpg"
        />
        <ServiceLeft
          title="Music Monetization"
          text="Watch revenue from YouTube Content ID and from Soundcloud come in while we certify your copyrights’ protection. Increase your music revenues on Facebook, Instagram, TikTok, YouTube and SoundCloud."
          image="./assets/images/services/mm.jpg"
        />
        <ServiceLeft
          title="Promotion & Marketing"
          text="Fly to the top of the charts with high-quality marketing services including social media optimization, playlist pitching, digital marketing, branding, creative design, release design packages, video edition, advertising and more."
          image="./assets/images/services/pmpp.jpg"
        />
        <ServiceLeft
          title="Royalty & Catalogue Dashboard"
          text="Easy-to-use interface for streaming analytics, royalty payments and reports, easy catalogue transfer solutions and more."
          image="./assets/images/services/rcd.jpg"
        />
        <ServiceLeft
          title="24/7 Support"
          text="Our client support team answers every single email in less than 24hrs, so if you have any issues these won’t hold you back from expanding your career."
          image="./assets/images/services/247.jpg"
        />
        <ServiceLeft
          title="Artist Profile Verification"
          text="We can assist our artists in landing verification on major platforms: YouTube Official Artist Channels, Instagram/Facebook, Spotify, Apple Music & Many more."
          image="./assets/images/services/apv.jpg"
        />
      </VStack>
      <VStack
        p={[8, 12, 16, 20]}
        pt={20}
        w="100%"
        mt={0}
        bg="bgSecondary.base"
        color="#FFFFFF"
        minH="100vh"
        borderTopLeftRadius={[80, 100, 120, 150]}
      >
        <AnimatedHeading
          fontFamily="Giselle"
          fontSize={[48, 58, 80, 80]}
          fontWeight="bold"
          textAlign="center"
          color="textSecondary.base"
          lineHeight="1"
        >
          Market your music
        </AnimatedHeading>
        <Text
          color="textSecondary.body"
          fontFamily="Gilroy"
          fontSize={[18, 18, 16, 16]}
          textAlign="center"
          pt={5}
          w={["100%", "100%", "70%", "70%"]}
        >
          Amplify your music’s visibility with our exclusive marketing services
          designed to reach a broader audience. We offer targeted radio
          placements on leading stations, seamless integration of your music
          video on top video channels, and strategic social media campaigns to
          create viral buzz. Our comprehensive approach also includes engaging
          blog articles, dynamic street promotions, curated playlist placements,
          and more. With these multifaceted strategies, we ensure your music
          captures attention and gains traction across various platforms.
        </Text>
        <SimpleGrid w="100%" columns={[1, 1, 3, 3]} spacing={10} pt={20}>
          <VStack w="100%">
            <Image
              src="/assets/images/services/wwp.svg"
              w={250}
              objectFit="cover"
              aspectRatio={1}
              height={250}
            />
            <AnimatedHeading
              pt={5}
              fontFamily="Giselle"
              fontSize={[26, 26, 26, 26]}
              fontWeight="bold"
              textAlign="center"
              color="textSecondary.base"
              lineHeight="1"
            >
              WORK WITH PROFESSIONALS
            </AnimatedHeading>
            <Text
              color="textSecondary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 14, 14]}
              textAlign="justify"
            >
              Partner with a team of dedicated experts who have deep industry
              knowledge and extensive experience in the music world. Our skilled
              professionals are committed to guiding you through every step of
              your music career, ensuring that you receive top-notch support and
              strategies. With their expertise, you can confidently navigate the
              industry and achieve guaranteed success.
            </Text>
          </VStack>
          <VStack w="100%">
            <Image
              src="/assets/images/services/monitor.svg"
              w={250}
              objectFit="cover"
              aspectRatio={1}
              height={250}
            />
            <AnimatedHeading
              pt={5}
              fontFamily="Giselle"
              fontSize={[26, 26, 26, 26]}
              fontWeight="bold"
              textAlign="center"
              color="textSecondary.base"
              lineHeight="1"
              center={true}
            >
              TRACK AND MONITOR YOUR PROGRESS
            </AnimatedHeading>
            <Text
              color="textSecondary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 14, 14]}
              textAlign="justify"
            >
              Seamlessly oversee the effectiveness of your promotional
              activities through our intuitive app. Our real-time progress
              reporting ensures you are always informed about your campaign’s
              performance. With up-to-the-minute updates and detailed insights,
              you’ll never be left in the dark about how your promotions are
              performing and can make informed decisions to optimize your
              strategy.
            </Text>
          </VStack>
          <VStack w="100%">
            <Image
              src="/assets/images/services/result.svg"
              w={250}
              objectFit="cover"
              aspectRatio={1}
              height={250}
            />
            <AnimatedHeading
              pt={5}
              fontFamily="Giselle"
              fontSize={[26, 26, 26, 26]}
              fontWeight="bold"
              textAlign="center"
              color="textSecondary.base"
              lineHeight="1"
            >
              RESULT PROVEN SERVICES
            </AnimatedHeading>
            <Text
              color="textSecondary.body"
              fontFamily="Gilroy"
              fontSize={[16, 16, 14, 14]}
              textAlign="justify"
            >
              Experience marketing services that are meticulously designed and
              backed by a track record of success. Our expert team applies
              tested strategies and innovative techniques to ensure you achieve
              tangible and impressive results. With our services, you can be
              confident that your marketing efforts will lead to substantial and
              impactful outcomes.
            </Text>
          </VStack>
        </SimpleGrid>
      </VStack>
      <Footer isLargerThan800={isLargerThan800} />
    </Box>
  );
};

export default function ServicesScreen({ isLargerThan800 }) {
  return (
    <Box w="100%" h="100vh" bg="#F9F5ED">
      {!isLargerThan800 ? (
        <div>
          <Hero />
        </div>
      ) : (
        <SmoothScroll>
          <Hero isLargerThan800={isLargerThan800} />
        </SmoothScroll>
      )}
    </Box>
  );
}
