import { HStack, Box, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../App";
import { AnimatedHeading } from "../components/Animated";
const Box2 = {
    initial: {
      width: "100%",
      left: "10%",
      
    },
    animate: {
      width: "10%",
      left: "80%",
      transition: {
        delay: 0.2,
        duration: 0.8,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        left: "10%",
        transition: {
            duration: 0.8,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };
  const Box3 = {
    initial: {
      width: "100%",
      left: "0%",
      
      
    },
    animate: {
      width: "0%",
      left: "80%",
      transition: {
        duration: 1,
        delay: 0.5,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "100%",
        left: "0%",
        transition: {
            duration: 0.5,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };
const Box1 = {
    initial: {
      width: "10%",
      x: "0vw",
      
      
    },
    animate: {
        width: "10%",
        x: "0vw",
      transition: {
        duration: 1.0,
        ease: [0.87, 0, 0.13, 1],
      },
    },
    exit: {
        width: "10%",
        x: "0vw",
        transition: {
            duration: 0.8,
            delay: 0.3,
            ease: [0.87, 0, 0.13, 1],
          },
    },
    exitAlt:{
        width: "100%",
        x: "-70vw",
        transition: {
            duration: 0.8,
            delay: 0.3,
            ease: [0.87, 0, 0.13, 1],
          },
    }
  };

function About(props) {
    const navigate = useNavigate();
    const [altTrigger, setAltTrigger] = useState(false)
    useEffect(() => {
        if(altTrigger){
            navigate("/home",{ state: { direction: "right" } });
        }
    }, [altTrigger])
    const context = useContext(props.LocationContext);
    useEffect(() => {
        context.setLocation("about");
    }, [])
    
    
  return (
    <motion.div className="container">
      <HStack w="100%" spacing={0}>
      <motion.div
          style={{
            width: "0%",
            height: "100vh",
            //light red
            background: theme.colors.block3.base,
            position: "fixed",
            top: 0,
            left: "70%",
            
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={Box3}
          
          onClick={() => navigate("/about")}
        >
            
          
        </motion.div>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light yellow
            background: theme.colors.block2.base,
            position: "fixed",
            top: 0,
            right: "10%",
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          variants={Box2}
          
          onClick={() => navigate("/services", {state:{direction:"left"}})}
        >
            
            <Box
              height={"100vh"}
              width={"100%"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              pb={20}
              
            >
              <AnimatedHeading
                color={"white"}
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Giselle"}
                transform={"rotate(-90deg)"}
                textAlign={"left"}
              >
                Services
              </AnimatedHeading>
            </Box>
        </motion.div>
        <Container
            minH={"100vh"}
            width={"80%"}
        >
            
        </Container>
        <motion.div
          style={{
            width: "10%",
            height: "100vh",
            //light blue
            background: theme.colors.block1.base,
            position: "fixed",
            top: 0,
            left: "90%",
          }}
          initial="initial"
          animate="animate"
          exit={altTrigger ? "exitAlt" : "exit"}
          variants={Box1}
          
          onClick={() => {
            setAltTrigger(true);
          }}
        >
            <Box
              height={"100vh"}
              width={"100%"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
              pb={20}
              
            >
              <AnimatedHeading
                color={"white"}
                fontSize={30}
                fontWeight={"bold"}
                fontFamily={"Giselle"}
                transform={"rotate(-90deg)"}
                textAlign={"left"}
              >
                Home
              </AnimatedHeading>
            </Box>
          
        </motion.div>
      </HStack>
    </motion.div>
  );
}

export default About;