import logo from './logo.svg';
import './App.css';
import { Box, ChakraProvider, Container, useMediaQuery } from '@chakra-ui/react'
import { Text, Flex } from '@chakra-ui/react'
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import AnimatedRoutes from "./AnimatedRoutes";
import React from 'react';
import SmoothScroll from "./components/SmoothScroll/SmoothScroll";
import HomeScreen from './screens/HomeScreen';
import { extendTheme } from "@chakra-ui/react"
import Header from './components/Header';
import ServicesScreen from './screens/ServicesScreen';
import AboutScreen from './screens/AboutScreen';

// 2. Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  colors: {
    block2: {
      base: '#749AFB'
    },
    block3: {
      base: "#FF667D"
    },
    block1: {
      base: "#ff9776"
    },
    textPrimary: {
      heading: "#4A4A4A",
      body: "#00000080",
      base: "#4A4A4A",
    },
    textSecondary: {
      base: "#FFFFFF",
    },
    bgPrimary: {
      base: "#F9F5ED",
    },
    bgSecondary: {
      base: "#303860",
    }
  },
})


function App() {
  // create a context for the current location
  const LocationContext = React.createContext();
  // get the current location
  const [location, setLocation] = React.useState("home");
  //useMediaQuery
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  
  return (
    <LocationContext.Provider value={{ location, setLocation }}>
      <ChakraProvider theme={theme}>
        <div className="App">
          <Box
            pos={isLargerThan800 ? "fixed" : "relative"}
            //height={"100vh"}
            //overflow={"scroll"}
            top={0}
            left={
              !isLargerThan800
                ? "0vw"
                : location == "home"
                ? "20vw"
                : location == "about"
                ? "0vw"
                : location == "services"
                ? "10vw"
                : "20vw"
            }
            w={isLargerThan800 ? "80vw" : "100vw"}
          >
            {
              location == "home" ? (
              <HomeScreen
                isLargerThan800={isLargerThan800}
              />
              ) : 
              location == "about" ? (
                <AboutScreen 
                  isLargerThan800={isLargerThan800}
                />
                
              ) :
              location == "services" ? (
                <ServicesScreen
                  isLargerThan800={isLargerThan800}
                />
              ) : (
                <HomeScreen
                isLargerThan800={isLargerThan800}
              />
              )

              }

          </Box>

          {/* Desktop */}
          
          <Router>
          <Header location={location} setLocation={setLocation}  />
            <div
            style={{
              position: "relative",
              zIndex: isLargerThan800 ? 0 : -10,
            }}
            >
              <AnimatedRoutes LocationContext={LocationContext} />
              </div>
            
          </Router>
        </div>
      </ChakraProvider>
    </LocationContext.Provider>
  );
}

export default App;
