import {
  Box,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  theme,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
export const AnimatedHeading = (props) => {
  const text = props.children.split(" ");
  const center = props.center
  const propsWithoutChildren = { ...props };
  delete propsWithoutChildren.children;
  return (
    
    <Text {...propsWithoutChildren} textAlign="center">
      {text.map((word, index) => (
        <Text
          overflow={"hidden"}
          display={"inline-block"}
          key={index}
          lineHeight={1.25}
          textAlign="center"
        >
          <motion.div
            style={{
              display: "inline-block",
              textAlign: "center",
              // width: "100%"
            }}
            initial={{ y: "100%" }}
            whileInView={{ y: 0 }}
            transition={{
              delay: index * (props.delay || 0.1),
              duration: props.duration || 1.5,
              ease: [0.87, 0, 0.13, 1],
            }}
          >
            {word}
          </motion.div>
          {"\u00A0"}
        </Text>
      ))}
    </Text>
  );
};

export const TypingAnimation = (props) => {
  //split by letter
  const text = props.children.split("");
  const propsWithoutChildren = { ...props };
  delete propsWithoutChildren.children;
  const [lettersToShow, setLettersToShow] = useState(1);
  const [isTyping, setIsTyping] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(() => {
        setLettersToShow((prev) => prev + 1);
      }, 100);
      if (lettersToShow === text.length) {
        setIsTyping(false);
      }
      return () => clearTimeout(timeout);
    }
    if (isDeleting) {
      const timeout = setTimeout(() => {
        setLettersToShow((prev) => prev - 1);
      }, 100);
      if (lettersToShow === 1) {
        setIsDeleting(false);
        setIsTyping(true);
      }
      return () => clearTimeout(timeout);
    }
    const timeout = setTimeout(() => {
      setIsDeleting(true);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [isTyping, isDeleting, lettersToShow]);

  return (
    <Heading {...propsWithoutChildren}>
      {text.map(
        (letter, index) =>
          lettersToShow > index && <span key={index}>{letter}</span>
      )}
    </Heading>
  );
};
